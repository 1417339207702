import ajax from '@/utils/ajax4'
// import qs from 'qs'

export function login(data) {
    return ajax({
        url: `/api/login`,
        method: 'post',
        data
    })
}

export function getSightsInMapView(data) {
    return ajax({
        url: `api/trip/getSightsInMapView`,
        method: 'post',
        data
    })
}

export function getSightBasic(data) {
    return ajax({
        url: `api/trip/getSightBasic`,
        method: 'post',
        data
    })
}

export function getSightforWeb(data) {
    return ajax({
        url: `api/trip/getSightforWeb`,
        method: 'post',
        data
    })
}


export function getDistributeParams(data) {
    return ajax({
        url: `/api/distribution/getDistributeParams`,
        method: 'post',
        data
    })
}

export function getStores960site(data) {
    return ajax({
        url: `api/trip/getStores960site`,
        method: 'post',
        data
    })
}

export function getStoreBasic960site(data) {
    return ajax({
        url: `api/trip/getStoreBasic960site`,
        method: 'post',
        data
    })
}

export function getRoutePositions(data) {
    return ajax({
        url: `api/trip/getRoutePositions`,
        method: 'post',
        data
    })
}

export function getSightMarkers(data) {
    return ajax({
        url: `api/event/getSightMarkers`,
        method: 'post',
        data
    })
}


