<template>
  <div>
    <div class="container">
      <div id="mymap" style="width:100%;height:100%"></div>
      <div class="btnmode" @click="showLayer">
        <span v-if="satelliteMode">标准</span>
        <span v-else>卫星</span>
      </div>
    </div>

    <el-drawer
      :visible.sync="drawer"
      :direction="'btt'"
      :title="tapPosition.name"
      :modal="false"
      destroy-on-close
      @closed="handleClose"
      size="50%"
    >
      <div class="infobox">
        <div class="imgthumbbox">
          <img
            v-for="(item,i) in imgShow"
            :key="i"
            :src="item"
            @click="openPic()"
            alt
            class="imgwrap"
          />
        </div>
        <div class="infoset">
          <div class="itembox green">{{sight.typeName}}</div>
          <div class="itembox orange">自驾车{{sight.carInName}}</div>
        </div>
        <div class="introbox">门票：{{sight.price==0?'免费':sight.price+'元'}}</div>
        <div class="introbox" v-if="sight.intro">{{sight.intro}}</div>
      </div>
    </el-drawer>

    <el-dialog :visible.sync="dialogVisible" width="100%" top="2vh" destroy-on-close>
      <el-carousel height="100vh">
        <el-carousel-item v-for="item in imgs" :key="item">
          <div class="picbox">
            <img :src="item" alt class="picstyle" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>
<script>
import {
  getSightMarkers,
  getRoutePositions,
  getSightBasic
} from "../api/apiv4";
let map, satellite;
var AMap = window.AMap;
var labelsLayer = new AMap.LabelsLayer({
  zooms: [3, 20],
  zIndex: 1000,
  // 该层内标注是否避让
  collision: false,
  // 设置 allowCollision：true，可以让标注避让用户的标注
  allowCollision: true
});

export default {
  data() {
    return {
      id: "",
      type: "",
      positions: [],
      satelliteMode: false,
      latlng: {
        lat1: 0,
        lat2: 0,
        lng1: 0,
        lng2: 0
      },
      drawer: false,
      showTerminal: false,
      sights: [],
      markers: [],
      dialogVisible: false,
      dataObj: "",
      tapPosition: {},
      imgs: [],
      routePositions: [],
      polyline: [],
      sight: {},
      imgShow: []
    };
  },

  mounted() {
    this.id = this.getQueryVariable("id");
    this.type = this.getQueryVariable("type");
    if (this.type == 1) {
      this.getRoutePositions();
    } else {
      this.getSightMarkers();
    }
    this.initMap();
  },
  methods: {
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    initMap() {
      // const that = this;
      map = new AMap.Map("mymap", {
        zoom: 11, //设置地图显示的缩放级别
        // mapStyle: "amap://styles/whitesmoke", //设置地图的显示样式
        animateEnable: true
      });
      satellite = new window.AMap.TileLayer.Satellite({ zoom: 14 });
    },

    getSightMarkers() {
      const that = this;
      getSightMarkers({
        id: that.id
      }).then(res => {
        that.sights = res.data.sights;
        that.markers = res.data.markers;
        that.setMarkerSight();
      });
    },

    getRoutePositions() {
      const that = this;
      getRoutePositions({
        id: this.id
      }).then(res => {
        that.markers = res.data.markers;
        that.route = res.data.route;
        that.routePositions = res.data.routePositions;
        that.polyline = res.data.polyline;
        that.setMarkerSight();
      });
    },
    setMarkerSight() {
      const that = this;
      let lbMarkers = [];
      that.markers.forEach(element => {
        // let img = require("@/assets/sighticon0.png");
        var text = {
          // 要展示的文字内容
          content: element.label.content,
          // 文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
          direction: "bottom",
          // 在 direction 基础上的偏移量
          offset: [0, -5],
          // 文字样式
          style: {
            // 字体大小
            fontSize: 11,
            // 字体颜色
            fillColor: "#22886f",
            // 描边颜色
            strokeColor: "#fff",
            // 描边宽度
            strokeWidth: 2
          }
        };
        let iconPath = element.iconPath.replace(
          "https://server.solar960.com/ast",
          ""
        );
        let image = require("@/assets" + iconPath);

        var icon = {
          // 图标类型，现阶段只支持 image 类型
          type: "image",
          // 图片 url
          image: image,
          // 图片尺寸
          size: [20, 20]
          // 图片相对 position 的锚点，默认为 bottom-center
        };
        var labelMarker = new AMap.LabelMarker({
          name: element.id, // 此属性非绘制文字内容，仅最为标识使用
          position: [element.longitude, element.latitude],
          zIndex: 16,
          // 将第一步创建的 icon 对象传给 icon 属性
          icon: icon,
          // 将第二步创建的 text 对象传给 text 属性
          text: text
        });
        labelMarker.on("click", function(e) {
          that.onMarkerClick(e.target._opts.name);
        });
        lbMarkers.push(labelMarker);
      });
      this.lbMarkers = lbMarkers;
      labelsLayer.add(lbMarkers);
      map.add(labelsLayer);
      map.setFitView();
    },
    //marker点击事件
    // onMarkerClick(e) {
    //   const that = this;
    //   let tapMarker = that.markers.find(item => {
    //     return item.id == e;
    //   });
    //   if (e < 100000) {
    //     let tapPoint = that.sights.find(item => {
    //       return item.id == e;
    //     });

    //     tapPoint.nameTitle = tapPoint.name;
    //     that.tapPosition = tapPoint;
    //     that.showTerminal = false;
    //     that.drawer = true;
    //   } else {
    //     let nameTitle = "线路起点";
    //     let nameText = "起点名称";
    //     if (tapMarker.id == 1000002) {
    //       nameTitle = "线路终点";
    //       nameText = "终点名称";
    //     }
    //     that.tapPosition = tapMarker;
    //     that.tapPosition.nameText = nameText;
    //     that.tapPosition.nameTitle = nameTitle;
    //     that.drawer = true;
    //     that.showTerminal = true;
    //   }
    // },
    onMarkerClick(e) {
      console.log(e, "ffffffffffffff");
      const that = this;
      let tapPoint, idSight, text, routeSequence;
      if (e < 0) {
        let id = 0 - e;
        tapPoint = that.routePositions.find(item => {
          return item.id == id;
        });
        routeSequence = tapPoint.routeSequence - 0 + 1;
        that.tapPosition = tapPoint;
        switch (tapPoint.type) {
          case 1:
            idSight = tapPoint.idSight;
            text =
              "行程第" +
              routeSequence +
              "天第" +
              (tapPoint.daySequence + 1) +
              "个景点";
            break;
          case 2:
            text = "行程第" + routeSequence + "天住宿地";
            break;
          case 3:
            text = "自驾行程的起点";
            break;
          case 4:
            text = "自驾行程的终点";
            break;
          default:
            break;
        }
        if (tapPoint.idSight) {
          idSight = tapPoint.idSight;
        } else {
          idSight = false;
        }
      } else {
        tapPoint = that.sights.find(item => {
          return item.id == e;
        });
        idSight = tapPoint.id;
      }
      this.$message({
        message: text,
        type: "success"
      });

      if (idSight) {
        that.getSight(idSight);
      }
    },

    getSight(id) {
      const that = this;
      getSightBasic({
        id: id
      }).then(res => {
        that.sight = res.data;
        let imgs = []; //大图
        res.data.imgs.forEach(element => {
          imgs.push(element.replace("_thumb", ""));
        });
        this.imgShow = res.data.imgs.slice(0, 3);
        that.drawer = true;
      });
    },

    handleClose() {
      this.drawer = false;
      this.showTerminal = false;
      this.tapPosition = { id: null };
    },

    showLayer() {
      if (this.satelliteMode) {
        map.removeLayer(satellite);
        this.satelliteMode = false;
      } else {
        this.satelliteMode = true;
        map.addLayer(satellite);
      }
    },

    openPic() {
      this.imgs = this.imgShow;
      this.dialogVisible = true;
    },

    handlePicClose() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped>
.container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100vh;
}
.btnmode {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px 10px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  box-shadow: 0 0 5px #999;
}

.el-drawer__body {
  overflow: auto;
}

.imgthumbbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.imgwrap {
  width: 31%;
  margin-right: 2%;
  height: 80px;
  border-radius: 5px;
  border: 1px solid #eee;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.picbox {
  display: flex;
  justify-content: center;
  height: 400px;
}
.picstyle {
  height: 500px;
  border-radius: 10px;
  max-width: 80%;
}

.infobox {
  padding: 20px;
}

.imgcount {
  position: absolute;
  right: 3px;
  bottom: 5px;
  background: #555;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.introbox {
  margin-top: 5px;
  border-top: 1px dashed #aaa;
  padding: 10px 0;
}

.infoset {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.itembox {
  font-size: 12px;
  padding: 3px 11px;
  border-radius: 3px;
  margin: 10px 10px 0 0;
}

.orange {
  border: 1px solid #ff8c00;
  color: darkorange;
}

.green {
  border: 1px solid mediumseagreen;
  color: mediumseagreen;
}

.headwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.titlewrap {
  font-size: 16px;
  font-weight: bold;
}
.closeicon {
  padding: 2px 6px;
  border: 1px solid #999;
  border-radius: 14px;
  color: #999;
  font-size: 12px;
}
</style>